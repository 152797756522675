import { TranslateResult } from "vue-i18n";
import { Institutes } from "@/interfaces/data_objects/institutes";
import { Programs } from "@/interfaces/data_objects/programs";

export enum InstituteType {
  NATIONAL,
  GLOBAL
}

// Interface for select degree component
export interface SelectDegree {
  doc_type: SelectDegreeDocType;
  paste_content_rules: { (value: string): boolean | TranslateResult }[];
  search_institute: string;
  search_program: string;
  searched_institutes: Institutes[];
  searched_programs: Programs[];
  selected_institute: Institutes | null;
  selected_program: Programs | null;
  all_institute_programs: Programs[];
  selected_degree_file: File | null;
  selected_degree_content: string;
}

export enum SelectDegreeDocType {
  FILE,
  TEXT
}
